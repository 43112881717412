import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './index.css';
import Pwm2 from './containers/pwm2';
import configureStore from './store/configure-store';
import defaultState from './store/default-state';
import * as serviceWorker from './serviceWorker';

const initialState = {
  pwm2: defaultState
};

const store = configureStore(initialState);

ReactDOM.render(
  <Provider store={store}>
    <Pwm2 />
  </Provider>, document.getElementById('root'));

serviceWorker.register();
