import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as pw2Actions from '../actions/pwm2-actions';
import Pwm2 from '../components/pwm2';

function mapStateToProps(state, props) {
  return {
    ...state.pwm2
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(pw2Actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pwm2);
