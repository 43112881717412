import * as types from '../actions/action-types';
import defaultState from '../store/default-state';

export default (state = {}, action) => {
  switch (action.type) {
    case types.ERROR: {
      const { error } = action;
      return {
        ...defaultState,
        error,
        isLoading: false
      };
    }

    case types.REQUEST_LOGIN: {
      return {
        ...state,
        error: null,
        isLoading: true
      };
    }

    case types.RECEIVE_LOGIN: {
      const { data: { token } } = action;
      return {
        ...state,
        token,
        isLoading: false
      };
    }

    case types.REQUEST_RECORDS: {
      return {
        ...state,
        error: null,
        isLoading: true
      };
    }

    case types.RECEIVE_RECORDS: {
      const { data } = action;
      return {
        ...state,
        records: data,
        isLoading: false
      };
    }

    case types.SELECT_RECORD: {
      const { record } = action;
      return {
        ...state,
        selectedRecord: record
      };
    }

    case types.REQUEST_RECORD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case types.RECEIVE_RECORD: {
      const { data } = action;
      return {
        ...state,
        record: data,
        isLoading: false
      };
    }

    case types.CHANGE_RECORD: {
      const { record } = action;
      return {
        ...state,
        record,
        selectedRecord: record.site
      };
    }

    case types.REQUEST_SAVE_RECORD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case types.RECEIVE_SAVE_RECORD: {
      const { site, data } = action;
      return {
        ...state,
        newRecord: false,
        selectedRecord: site,
        records: data,
        isLoading: false
      };
    }

    case types.REQUEST_LOGOUT: {
      return {
        ...defaultState,
        isLoading: true
      };
    }

    case types.RECEIVE_LOGOUT: {
      return {
        ...defaultState,
        isLoading: false
      };
    }

    case types.NEW_RECORD: {
      return {
        ...state,
        newRecord: true,
        selectedRecord: '',
        record: {
          userId: '',
          password: '',
          notes: ''
        }
      };
    }

    case types.NEW_CANCEL: {
      return {
        ...state,
        newRecord: false,
        selectedRecord: '',
        record: null
      };
    }

    case types.REQUEST_DELETE_RECORD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case types.RECEIVE_DELETE_RECORD: {
      return {
        ...state,
        selectedRecord: '',
        records: action.data,
        record: null,
        isLoading: false
      };
    }

    case types.SET_PASSWORD: {
      const { password } = action;
      return {
        ...state,
        record: {
          ...state.record,
          password
        }
      };
    }

    default:
      return state;
  }
};
