import * as types from './action-types';

export function attemptLogin(host, user, password) {
    return {
        api: {
            endpoint: `${host}/pwm2/login`,
            types: [types.REQUEST_LOGIN, types.RECEIVE_LOGIN, types.ERROR],
            method: 'POST',
            body: {
                user,
                password
            }
        }
    };
}

export function requestRecords(host, token) {
    return {
        api: {
            endpoint: `${host}/pwm2/records`,
            types: [types.REQUEST_RECORDS, types.RECEIVE_RECORDS, types.ERROR],
            token
        }
    };
}

export function selectRecord(record) {
    return {
        type: types.SELECT_RECORD,
        record
    }
}

export function requestRecord(host, token, site) {
    return {
        api: {
            endpoint: `${host}/pwm2/record`,
            types: [types.REQUEST_RECORD, types.RECEIVE_RECORD, types.ERROR],
            method: 'POST',
            token,
            body: {
                site
            }
        }
    };
}

export function changeRecord(record) {
    return {
        type: types.CHANGE_RECORD,
        record
    }
}

export function saveRecord(host, token, site, record) {
    return {
        api: {
            endpoint: `${host}/pwm2/record`,
            types: [types.REQUEST_SAVE_RECORD, types.RECEIVE_SAVE_RECORD, types.ERROR],
            method: 'PUT',
            token,
            body: {
                site,
                ...record
            }
        },
        site
    };
}

export function logout(host, token) {
    return {
        api: {
            endpoint: `${host}/pwm2/logout`,
            types: [types.REQUEST_LOGOUT, types.RECEIVE_LOGOUT, types.ERROR],
            token
        }
    };
}

export function newRecord() {
    return {
        type: types.NEW_RECORD
    }
}

export function newCancel() {
    return {
        type: types.NEW_CANCEL
    }
}

export function deleteRecord(host, token, site) {
    return {
        api: {
            endpoint: `${host}/pwm2/record`,
            types: [types.REQUEST_DELETE_RECORD, types.RECEIVE_DELETE_RECORD, types.ERROR],
            method: 'DELETE',
            token,
            body: {
                site
            }
        }
    };
}

export function setPassword(password) {
    return {
        type: types.SET_PASSWORD,
        password
    }
}