import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import apiMiddleware from './api-middleware';
import rootReducer from '../reducers';

export default (initialState) => {
  const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  const middleware = composeEnhancers(
      applyMiddleware(
          thunk,
          apiMiddleware()
      )
  );

  return createStore(rootReducer, initialState, middleware);
};
