import React from 'react';

import Button from 'react-bootstrap/lib/Button';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Form from 'react-bootstrap/lib/Form';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';

const FormLogin = ({ host, token, actions }) => {
    let defaultValueUser = '';
    let defaultValuePassword = '';
    if (process.env.NODE_ENV === 'development') {
        defaultValueUser = 'test';
        defaultValuePassword = '5up3rSecret';
    }

    const handleLogin = async (event) => {
        event.preventDefault();
        const user = event.target[0].value;
        const password = event.target[1].value;
        try {
            const { token } = await actions.attemptLogin(host, user, password);
            if (token) {
                actions.requestRecords(host, token);
            }
        }
        catch (err) {
        };
    }

    return (
        <Form onSubmit={handleLogin}>
            <FormGroup controlId="formUser">
                <ControlLabel>User</ControlLabel>
                <FormControl type="text" placeholder="User" name="user" defaultValue={defaultValueUser} autoCapitalize="none" autoFocus />
            </FormGroup>

            <FormGroup controlId="formPassword">
                <ControlLabel>Password</ControlLabel>
                <FormControl type="password" placeholder="Password" name="password" defaultValue={defaultValuePassword} />
            </FormGroup>

            <FormGroup>
                <Button type="submit">Login</Button>
            </FormGroup>
        </Form>
    )
};

export default FormLogin
