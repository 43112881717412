import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Form from 'react-bootstrap/lib/Form';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';

import Select from 'react-select';
import 'react-select/dist/react-select.css';

import Pwm2Nav from './pwm2-nav.js';
import FormLogin from './form-login';
import FormEdit from './form-edit';
import Error from './error';

class Pwm2 extends Component {
    recordChange = async (newRecord) => {
        const { actions, host, token } = this.props;
        const site = newRecord ? newRecord.value : '';
        const promises = [
            actions.selectRecord(site)
        ];
        if (site) {
            promises.push(actions.requestRecord(host, token, site));
        }
        await Promise.all(promises);
    };

    handleChange = async (event) => {
        const { actions, selectedRecord, record } = this.props;
        const changeRecord = {
            ...record,
            site: selectedRecord,
            [event.target.name]: event.target.value.replace(/\n/g, '\r\n')
        };

        await actions.changeRecord(changeRecord);
    }

    render() {
        const { error, token, records: recordsData, selectedRecord, record, newRecord, isLoading } = this.props;

        const recordsOptions = (recordsData && recordsData.map((record, index) => (
            { value: record, label: record.substr(0, 1) === ' ' ? `>>${record}` : record }
        )));

        let ui = [];

        if (!token || error) {
            ui.push(
                <Row key={ui.length}>
                    <FormLogin {...this.props} />
                </Row>
            );

            if (error) {
                ui.push(
                    <Row key={ui.length}>
                        <Error {...error} />
                    </Row>
                );
            }
        }

        if (!ui.length) {
            ui.push(
                <Row key={ui.length}>
                    <Form>
                        <FormGroup controlId="formSite">
                            <ControlLabel>Site</ControlLabel>
                            {newRecord &&
                                <FormControl type="text" placeholder="Site" name="site" value={selectedRecord} onChange={this.handleChange} autoFocus autoCapitalize="none" />
                            }
                            {!newRecord &&
                                <Select
                                    name="site"
                                    value={selectedRecord}
                                    onChange={this.recordChange}
                                    searchable
                                    options={recordsOptions}
                                    clearable
                                    className="select-desktop"
                                    autoFocus
                                />
                            }
                        </FormGroup>
                    </Form>
                </Row>
            );

            if (record) {
                ui.push(
                    <Row key={ui.length}>
                        <FormEdit {...this.props} handleChange={this.handleChange} />
                    </Row>
                );
            }
        }

        return (
            <Grid>
                <Pwm2Nav {...this.props} />
                <Grid className="pwm2">
                    { !isLoading && ui }
                </Grid>
            </Grid>
        );
    }
}

Pwm2.propTypes = {
    actions: PropTypes.object.isRequired,
    error: PropTypes.object,
    host: PropTypes.string,
    token: PropTypes.string,
    records: PropTypes.array,
    selectedRecord: PropTypes.string,
    record: PropTypes.object,
    newRecord: PropTypes.bool,
    isLoading: PropTypes.bool
};

export default Pwm2;
