import React from 'react';

import Navbar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';
import NavItem from 'react-bootstrap/lib/NavItem';

import 'font-awesome/css/font-awesome.css';

import * as randomWords from 'random-words';

const Pwm2Nav = ({ host, token, selectedRecord, record, newRecord, actions, isLoading }) => {
    const wordMunger = (word) => (word.charAt(0).toUpperCase() + word.slice(1).replace('a', '@').replace('e', '#').replace('i', '1').replace('s', '5'));

    const handleSelect = async (eventKey) => {
        const GENERATE = 1;
        const DELETE = 2;
        const CANCEL = 3;
        const SAVE = 4;
        const NEW = 5;
        const LOGOUT = 6;

        if (eventKey === GENERATE) {
            const randomPassword = `${randomWords({ exactly: 3, join: '-', formatter: word => wordMunger(word) })}!`;
            await actions.setPassword(randomPassword);
        }
        else if (eventKey === DELETE) {
            if (window.confirm(`Are you sure you want to delete "${selectedRecord}"?`)) {
                await actions.deleteRecord(host, token, selectedRecord);
            }
        } else if (eventKey === CANCEL) {
            await actions.newCancel();
        } else if (eventKey === SAVE) {
            if (!selectedRecord) {
                window.alert('Please enter a value for "Site" before attempting to Save.');
            } else {
                await actions.saveRecord(host, token, selectedRecord, record);
            }
        } else if (eventKey === NEW) {
            await actions.newRecord();
        } else if (eventKey === LOGOUT) {
            await actions.logout(host, token);
        }
    }

    const icon = isLoading ? <i className="fa fa-refresh fa-spin"></i> : <img src="/transparent-icon-128px.png" alt="Pwm2" width="24"/>

    return (
        <Navbar inverse fixedTop collapseOnSelect>
            <Navbar.Header>
                <Navbar.Brand>
                    <a href="#brand">{icon}</a>&nbsp;&nbsp;<a href="#brand">Pwm2</a>
                </Navbar.Brand>
                {token &&
                    <Navbar.Toggle />
                }
            </Navbar.Header>
            {token &&
                <Navbar.Collapse>
                    <Nav pullRight onSelect={(k) => handleSelect(k)}>
                        {(selectedRecord || newRecord) &&
                            <NavItem eventKey={1} href="#">
                                Generate Password
                            </NavItem>
                        }
                        {(selectedRecord && !newRecord) &&
                            <NavItem eventKey={2} href="#">
                                Delete
                            </NavItem>
                        }
                        {(newRecord) &&
                            <NavItem eventKey={3} href="#">
                                Cancel
                            </NavItem>
                        }
                        {(selectedRecord || newRecord) &&
                            <NavItem eventKey={4} href="#">
                                Save
                            </NavItem>
                        }
                        {(!newRecord) &&
                            <NavItem eventKey={5} href="#">
                                New
                            </NavItem>
                        }
                        <NavItem eventKey={6} href="#">
                            Logout
                        </NavItem>
                    </Nav>
                </Navbar.Collapse>
            }
        </Navbar>
    )
};

export default Pwm2Nav
