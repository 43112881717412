import React from 'react';

import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Form from 'react-bootstrap/lib/Form';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';

const FormEdit = ({ record: { userId, password, notes }, newRecord, handleChange }) => {
    const selectOnFocus = (event) => {
        event.target.setSelectionRange(0, 99999);
    };

    return (
        <Form>
            <FormGroup controlId="formEdit">
                <ControlLabel>User</ControlLabel>
                <FormControl type="text" placeholder="User" name="userId" value={userId} onFocus={!newRecord ? selectOnFocus : null} onChange={handleChange} autoCapitalize="none" />
            </FormGroup>

            <FormGroup controlId="formPassword">
                <ControlLabel>Password</ControlLabel>
                <FormControl type="text" placeholder="Password" name="password" value={password} onFocus={!newRecord ? selectOnFocus : null} autoFocus={!newRecord} onChange={handleChange} autoCapitalize="none" />
            </FormGroup>

            <FormGroup controlId="formNotes">
                <ControlLabel>Notes</ControlLabel>
                <FormControl componentClass="textarea" placeholder="Notes" name="notes" value={notes} rows="10" onChange={handleChange} autoCapitalize="none" />
            </FormGroup>
        </Form>
    )
};

export default FormEdit
