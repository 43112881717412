export const ERROR = 'ERROR';

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN';

export const REQUEST_RECORDS = 'REQUEST_RECORDS';
export const RECEIVE_RECORDS = 'RECEIVE_RECORDS';

export const SELECT_RECORD = 'SELECT_RECORD';

export const REQUEST_RECORD = 'REQUEST_RECORD';
export const RECEIVE_RECORD = 'RECEIVE_RECORD';

export const CHANGE_RECORD = 'CHANGE_RECORD';

export const REQUEST_SAVE_RECORD = 'REQUEST_SAVE_RECORD';
export const RECEIVE_SAVE_RECORD = 'RECEIVE_SAVE_RECORD';

export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const RECEIVE_LOGOUT = 'RECEIVE_LOGOUT';

export const NEW_RECORD = 'NEW_RECORD';
export const NEW_CANCEL = 'NEW_CANCEL';

export const REQUEST_DELETE_RECORD = 'REQUEST_DELETE_RECORD';
export const RECEIVE_DELETE_RECORD = 'RECEIVE_DELETE_RECORD';

export const SET_PASSWORD = 'SET_PASSWORD';
